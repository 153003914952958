<template>
  <div class="table-top-left">
    <div class="top-content">
      <h6>Composition Corrections</h6>
    </div>
    <div class="data-strip">
      <div>
        <div
          v-for="(field, index) in fields"
          :key="index"
          class="data-field"
        >
          <div class="field-label" v-if="field.value">
            <span class="label">{{ field.label }}</span>
            <span class="value">{{ field.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableTopLeftComponent",
   props: {
    filterData: {
      type: Object,
      required: true
    },
   },
  computed: {
    fields() {
      return [
        {
          label: "Asset",
          value: this.filterData.asset.join(', ')
        },
        {
          label: "Field",
          value: this.filterData.field.join(', ')
        },
        {
          label: "Well",
          value: this.filterData.well.join(', ')
        },
        {
          label: "Sample Type",
          value: this.filterData.sampleType.join(', ')
        },
        {
          label: "Sample",
          // value: this.filterData.sample.map(sample => sample.name).join(', ')
          value: this.filterData.sample.join(', ')
        }
      ];
    }
  }
};
</script>