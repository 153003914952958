<template>
  <div class="login-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="d-none d-md-block col-md-7 col-lg-8">
          <AuthLeftComponent />
        </div>
        <div class="col-12 col-md-5 col-lg-4">
            <AuthFormComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthLeftComponent from "@/components/Auth/AuthLeftComponent.vue";
import AuthFormComponent from "@/components/Auth/AuthFormComponent.vue";
export default {
  components: { AuthLeftComponent, AuthFormComponent },
};
</script>

<style>
</style>