<template>
  <div class="sample-select-wrapper">
    <div class="sample-selection">
      <form>
        <div class="row no-gutters">
          <div class="col">
            <h6>Select sample from file</h6>
            <div class="form-control">
              <input type="file" class="form-control-file" @change="handleFileUpload" />
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="table-top-right justify-content-start">
      <button type="button" class="btn" @click="next" :disabled="!selectedFile">
        <span>Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportFromDatabaseComponent",
  data() {
    return {
      requestID: "",
      selectedFile: null,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    next() {
      if (this.selectedFile) {
        this.$emit("open-sample-correction-with-file", this.selectedFile);
      } else {
        alert("Select a file.");
      }
    },
  },
  mounted() {
  },
};
</script>
