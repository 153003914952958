<template>
  <div>
    <DataComparisonModalComponent ref="comparisonModal" 
    @sample-updated="fetchNewSampleData"
    :location="sampleData?.location" :sampleData="sampleData" />
    <AIWorkFlowModalComponent 
      ref="aiWorkFlowModal"
      :sampleData="sampleData"
      :yAxisScale="yAxisScale" 
    />
    <div class="table-top-right mt-0">
      <button type="button" class="btn" @click="callModal()">
        <img :src="ai_corrected_workflow" alt="ai_corrected_workflow" />
        <span>AI Corrected Workflow</span>
      </button>
      <button type="button" class="btn" v-if="isChart" @click="showTableComponent">
        <img :src="show_data" alt="show_data" />
        <span>Show Data</span>
      </button>
      <button type="button" class="btn" v-if="isTable" @click="showChartComponent">
        <img :src="show_data" alt="show_data" />
        <span>Show Chart</span>
      </button>
      <button type="button" class="btn" v-if="isTable" @click="showModal">
        <img :src="show_comparison" alt="show_data" />
        <span>Show Comparison</span>
      </button>
      <button type="button" class="btn" @click="exportData" :disabled="exporting">
        <img :src="export_data" alt="export" />
        <span>{{ exporting ? 'Exporting...' : 'Export' }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import DataComparisonModalComponent from "./ComparisonData/DataComparisonModalComponent.vue";
import AIWorkFlowModalComponent from './AIWorkflow/AIWorkFlowModalComponent.vue';

export default {
  name: "ChartDataTopRightComponent",
  components: { DataComparisonModalComponent, AIWorkFlowModalComponent },
  props: {
    isCorrected: Boolean,
    view: String,
    location: Object,
    sampleData: Object,
    yAxisScale: Boolean
  },
  data() {
    return {
      ai_corrected_workflow: require("../../assets/images/ViewSummary/illustrator.png"),
      show_data: require("../../assets/images/ViewSummary/grid-4.png"),
      show_comparison: require("../../assets/images/table/comparison.png"),
      export_data: require("../../assets/images/table/export.png"),
      exporting: false,
    };
  },
  computed: {
    isChart() {
      return this.view === "chart";
    },
    isTable() {
      return this.view === "table";
    },
  },
  methods: {
    fetchNewSampleData(newSampleId) {
      this.$emit("sample-updated", newSampleId);
    },
    showTableComponent() {
      this.$emit("viewChanged", "table");
    },
    showChartComponent() {
      this.$emit("viewChanged", "chart");
    },
    showModal() {
      this.$refs.comparisonModal.openModal();
    },
    callModal() {
      this.$refs.aiWorkFlowModal.openModal();
    },
    async exportData() {
      this.exporting = true;
      try {
        const csvData = this.convertToCSV(this.sampleData);
        this.downloadCSV(csvData, `${this.sampleData.name}.csv`);
      } finally {
        this.exporting = false;
      }
    },
    convertToCSV(data) {
      const headers = [
        "Component",
        "MW",
        "Density",
        "Flashed Gas (mol%)",
        "Flashed Gas (wt%)",
        "Flashed Liquid (mol%)",
        "Flashed Liquid (wt%)",
        "Overall (mol%)",
        "Overall (wt%)",
      ];

      const rows = data.correctedComposition.composition.map((item) => [
        item.component,
        (item.mw || 0).toFixed(2),
        (item.density || 0).toFixed(3),
        (item.stockTankGas_MoleFraction || 0).toFixed(3),
        (item.stockTankGas_MassFraction || 0).toFixed(3),
        (item.stockTankOil_MoleFraction || 0).toFixed(3),
        (item.stockTankOil_MassFraction || 0).toFixed(3),
        (item.reservoirLiquid_MoleFraction || 0).toFixed(3),
        (item.reservoirLiquid_MassFraction || 0).toFixed(3),
      ]);


      const csvContent = [
        headers.join(","),
        ...rows.map((row) => row.join(",")),
      ].join("\n");

      return csvContent;
    },
    downloadCSV(csvContent, filename) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>
