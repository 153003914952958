<template>
  <div class="view-top-wrapper">
    <div class="row no-gutters align-items-center top-content">
      <div class="col-12 col-lg-4 col-xl-6">
        <ChartDataTopLeftComponent :location="sampleData?.location" :sampleData="sampleData"
          @sample-updated="fetchNewSampleData" />
      </div>
      <div class="col-12 col-lg-8 col-xl-6 py-3 py-lg-0">
        <ChartDataTopRightComponent 
        :isCorrected="sampleData?.isCorrected" 
        :view="currentView"
        @viewChanged="handleViewChange" 
        :location="sampleData?.location" 
        :sampleData="sampleData" 
        :yAxisScale="yAxisScale"
        />
      </div>
    </div>
    <div class="row no-gutters view-chart-wrapper">
      <div class="col px-5" v-if="isChart">
        <div class="toggle-select-wrapper">
          <div class="toggle-container">
            <span class="toggle-label">Linear</span>
            <label class="toggle">
              <input type="checkbox" v-model="yAxisScale" />
              <span class="slider round"></span>
            </label>
            <span class="toggle-label">Logarithmic</span>
          </div>
        </div>
        <ScatterChart
          :localSampleData="localSampleData" 
          :yAxisScale="yAxisScale" />
        <ChartBottomComponent 
          @updateDataOnCheck="getFilteredData" 
          :chartType="chartType"
        />
      </div>
      <div class="col" v-if="isTable">
        <TableData :location="sampleData?.location" :sampleData="sampleData" @sample-updated="fetchNewSampleData" />
      </div>
    </div>
  </div>
</template>

<script>
import ChartDataTopLeftComponent from "./ChartDataTopLeftComponent.vue";
import TableData from "./ShowData/TableDataComponent.vue";
import ChartBottomComponent from "@/components/ViewSummary/ChartBottomComponent.vue";
import ChartDataTopRightComponent from "./ChartDataTopRightComponent.vue";
import ScatterChart from "@/components/Charts/ScatterChart.vue";  
import { getFilteredData } from '@/utils/useFilteredData.js'; 


export default {
  name: "ChartDataComponent",
  components: {
    ChartDataTopLeftComponent,
    TableData,
    ChartBottomComponent,
    ChartDataTopRightComponent,
    ScatterChart,  
  },
  props: {
    sampleData: Object,
  },
  data() {
    return {
      chartType: "all",
      currentView: "chart",
      originalSampleData: null,
      localSampleData: null,
      yAxisScale: false,
    };
  },
  computed: {
    isChart() {
      return this.currentView === "chart";
    },
    isTable() {
      return this.currentView === "table";
    },
  },
  mounted() {
    const savedView = localStorage.getItem("currentView");
    if (savedView) {
      this.currentView = savedView;
    }
    this.originalSampleData = JSON.parse(JSON.stringify(this.sampleData));
    this.localSampleData = JSON.parse(JSON.stringify(this.sampleData));

    if (this.currentView === "chart") {
      this.$nextTick(() => {
        // The chart is now handled by the ChartComponent
      });
    }
  },
  methods: {
      handleViewChange(view) {
        this.currentView = view;
        localStorage.setItem("currentView", view);
      },
      fetchNewSampleData(newSampleId) {
        this.$emit("update-sample", newSampleId);
      },
      getFilteredData(filterCriteria) {
        this.localSampleData = getFilteredData(filterCriteria, this.originalSampleData, this.sampleData);
      }
  },
  watch: {
    sampleData: {
      immediate: true,
      handler(newSampleData) {
        const savedView = localStorage.getItem("currentView");
        if (savedView) {
          this.currentView = savedView;
        }
        this.localSampleData = JSON.parse(JSON.stringify(newSampleData));
      },
    },
  },
};
</script>
