<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#FilterDelete"
      ref="openModalFilterDelete"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="FilterDelete">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="delete-modal-content">
                <img :src="delete_icon" alt="delete">
                <p>Do you really want to delete this filter? This process cannot be undone</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn cancel">Cancel</button>
            <button type="button" class="btn" @click="deleteFilter" :disabled="loading">
              <span v-if="loading">
                <span>Deleting...</span>
              </span>
              <span v-else class="export-sec">
                <span>Delete</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery'; // Import jQuery if not already imported

export default {
  name: "DeleteFilterModalComponent",
  data() {
    return {
      delete_icon: require("../../../assets/images/modal/circle-xmark 1.png"),
      filterId: '',
      loading: false
    };
  },
  methods: {
    openModal(option) {
      this.filterId = option.id;
      var elem = this.$refs.openModalFilterDelete;
      if (elem) {
        elem.click();
      }
    },
    reopenParentModal() {
      this.$emit('reopenParentModal');
    },
    deleteFilter() {
      this.loading = true;
      axios.delete(`filters/${this.filterId}`)
        .then(response => {
          this.$emit("fetch-reset-filter");
          this.closeModal();
          this.reopenParentModal();
        })
        .catch(error => {
          console.error('Error deleting filter:', error);
        })
        .finally(()=> {
          this.loading = false;
        })
    },
    closeModal() {
      $('#FilterDelete').modal('hide');
    }
  },
};
</script>
