import axios from 'axios';
import router from '../router';

const state = {
  user: null,
  token: localStorage.getItem('token') || '',
  permissions: []
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    state.permissions = user.permissions || [];
  },
  setToken(state, token) {
    state.token = token;
  },
  clearAuthData(state) {
    state.user = null;
    state.token = '';
    state.permissions = [];
  }
};


const actions = {
  login({ commit }, credentials) {
    return axios.post('/login', credentials)
      .then(response => {
        if (response.data.statusCode === 200) {
          const userData = response.data.data;
          commit('setUser', userData);
          commit('setToken', userData.token);
          localStorage.setItem('token', userData.token);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + userData.token;
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  },
  logout({ commit }) {
    commit('clearAuthData');
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  },
  tryAutoLogin({ commit }) {
    const token = localStorage.getItem('token');
    if (!token) return;

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    axios.get('/me')
      .then(response => {
        if (response.data.statusCode === 200) {
          commit('setUser', response.data.data);
          commit('setToken', token);
        } else {
          commit('clearAuthData');
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
          router.push('/login');
        }
      })
      .catch(() => {
          commit('clearAuthData');
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
          router.push('/login');
      });
  }
};

const getters = {
  isAuthenticated(state) {
    return state.token !== '';
  },
  user(state) {
    return state.user;
  },
  hasPermission: (state) => (permissionName) => {
    return state.permissions.some(permission => permission.name === permissionName);
  },
  can: (state) => (endpoint, method) => {
    return state.permissions.some(permission =>
      permission.apis.some(api =>
        api.endpoint === endpoint && api.method === method.toLowerCase()
      )
    );
  }
};

export default {
  state,
  mutations,
  actions,
  getters
};
