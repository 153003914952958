export function getFilteredData(filterCriteria, originalSampleData, sampleData) {
    const glossaryMap = {
      flashed_gas: 'stockTankGas_MoleFraction',
      sto_corrected_composition: 'stockTankOil_MoleFraction',
      sto_uncorrected_composition: 'stockTankOil_MoleFraction',
      separator_liquid_corrected: 'separatorLiquid_MoleFraction',
      separator_liquid_uncorrected: 'separatorLiquid_MoleFraction',
      separator_gas: 'separatorGas_MoleFraction',
      reservoir_fluid_corrected: 'reservoirLiquid_MoleFraction',
      reservoir_fluid_uncorrected: 'reservoirLiquid_MoleFraction',
    };
  
    const filterCompositions = (composition, dataKey) => {
      return composition.map(composit => ({
        component: composit.component,
        mw: composit.mw,
        mw_UOM: composit.mw_UOM,
        [dataKey]: composit[dataKey],
      })).filter(item => item !== null);
    };
  
    let anyFilterApplied = false;
  
    const newSampleData = {
      correctedComposition: { composition: [] },
      originalComposition: { composition: [] },
    };
  
    const mergeCompositions = (targetCompositions, sourceCompositions) => {
      sourceCompositions.forEach(newComp => {
        const existingComp = targetCompositions.find(comp => comp.component === newComp.component);
        if (existingComp) {
          Object.assign(existingComp, newComp);
        } else {
          targetCompositions.push(newComp);
        }
      });
    };
  
    const sortCompositionsByOriginalOrder = (targetCompositions, originalCompositions) => {
      const componentOrder = originalCompositions.map(comp => comp.component);
      targetCompositions.sort((a, b) => componentOrder.indexOf(a.component) - componentOrder.indexOf(b.component));
    };
  
    // Apply filters based on filterCriteria
    if (filterCriteria.sto_corrected_composition) {
      const correctedDataKey = glossaryMap['sto_corrected_composition'];
      mergeCompositions(newSampleData.correctedComposition.composition, filterCompositions(originalSampleData.correctedComposition.composition, correctedDataKey));
      anyFilterApplied = true;
    }
  
    if (filterCriteria.separator_liquid_corrected) {
      const correctedDataKey = glossaryMap['separator_liquid_corrected'];
      mergeCompositions(newSampleData.correctedComposition.composition, filterCompositions(originalSampleData.correctedComposition.composition, correctedDataKey));
      anyFilterApplied = true;
    }
  
    if (filterCriteria.reservoir_fluid_corrected) {
      const correctedDataKey = glossaryMap['reservoir_fluid_corrected'];
      mergeCompositions(newSampleData.correctedComposition.composition, filterCompositions(originalSampleData.correctedComposition.composition, correctedDataKey));
      anyFilterApplied = true;
    }
  
    if (filterCriteria.sto_uncorrected_composition) {
      const uncorrectedDataKey = glossaryMap['sto_uncorrected_composition'];
      mergeCompositions(newSampleData.originalComposition.composition, filterCompositions(originalSampleData.originalComposition.composition, uncorrectedDataKey));
      anyFilterApplied = true;
    }
  
    if (filterCriteria.separator_liquid_uncorrected) {
      const uncorrectedDataKey = glossaryMap['separator_liquid_uncorrected'];
      mergeCompositions(newSampleData.originalComposition.composition, filterCompositions(originalSampleData.originalComposition.composition, uncorrectedDataKey));
      anyFilterApplied = true;
    }
  
    if (filterCriteria.reservoir_fluid_uncorrected) {
      const uncorrectedDataKey = glossaryMap['reservoir_fluid_uncorrected'];
      mergeCompositions(newSampleData.originalComposition.composition, filterCompositions(originalSampleData.originalComposition.composition, uncorrectedDataKey));
      anyFilterApplied = true;
    }
  
    if (filterCriteria.separator_gas) {
      const gasDataKey = glossaryMap['separator_gas'];
      mergeCompositions(newSampleData.correctedComposition.composition, filterCompositions(originalSampleData.correctedComposition.composition, gasDataKey));
      mergeCompositions(newSampleData.originalComposition.composition, filterCompositions(originalSampleData.originalComposition.composition, gasDataKey));
      anyFilterApplied = true;
    }
  
    if (filterCriteria.flashed_gas) {
      const gasDataKey = glossaryMap['flashed_gas'];
      mergeCompositions(newSampleData.correctedComposition.composition, filterCompositions(originalSampleData.correctedComposition.composition, gasDataKey));
      mergeCompositions(newSampleData.originalComposition.composition, filterCompositions(originalSampleData.originalComposition.composition, gasDataKey));
      anyFilterApplied = true;
    }
  
    // If no filters are applied, empty the chart
    if (!anyFilterApplied) {
      return {
        correctedComposition: { composition: [] },
        originalComposition: { composition: [] },
      };
    } else {
      sortCompositionsByOriginalOrder(newSampleData.correctedComposition.composition, sampleData.correctedComposition.composition);
      sortCompositionsByOriginalOrder(newSampleData.originalComposition.composition, sampleData.originalComposition.composition);
  
      return newSampleData;
    }
  }
  