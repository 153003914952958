<template>
  <div>
    <div class="table-container">
      <table class="table">
        <thead>
          <tr class="header-row">
            <th
              v-for="(header, index) in computedHeaders"
              :key="index"
              :colspan="header.colspan"
            >
              {{ header.label }}
              <span v-if="header.unitKey && !isChecked">({{ header.unitKey }})</span>
            </th>
          </tr>
          <tr class="units-row">
            <th v-for="(subHeader, index) in computedSubHeaders" :key="index">
              {{ subHeader }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            :class="{ 'even-row': index % 2 === 1 }"
          >
            <td>{{ item.component }}</td>
            <td>{{ item.mw }}</td>
            <td>{{ item.density }}</td>
            <td>{{ item.flashedGas.uncorrected }}</td>
            <td v-html="getCellContent(item.flashedGas)"></td>
            <td>{{ item.flashedLiquid.uncorrected }}</td>
            <td v-html="getCellContent(item.flashedLiquid)"></td>
            <td>{{ item.overall.uncorrected }}</td>
            <td v-html="getCellContent(item.overall)"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalTableComponent",
  props: {
    isCheckedPercentage: Boolean,
    isChecked: Boolean,
    sampleData: Object,
  },
  data() {
    return {
      left: require("../../../assets/images/table/left.png"),
      right: require("../../../assets/images/table/right.png"),
      headers: [
        { label: "Component", colspan: 1 },
        { label: "MW", colspan: 1 },
        { label: "Density", colspan: 1 },
        { label: "Flashed Gas", colspan: 2 },
        { label: "Flashed Liquid", colspan: 2 },
        { label: "Overall", colspan: 2 },
      ],
      tableData: [], // Will be filled dynamically
    };
  },
  computed: {
    unitKey() {
      return this.isCheckedPercentage ? "Mole %" : "wt %";
    },
    computedSubHeaders() {
      if (this.isChecked) {
        return [
          "",
          "g/mol",
          "g/cc",
          "Uncorrected",
          "% Delta",
          "Uncorrected",
          "% Delta",
          "Uncorrected",
          "% Delta",
        ];
      } else {
        return [
          "",
          "g/mol",
          "g/cc",
          "Uncorrected",
          "Corrected",
          "Uncorrected",
          "Corrected",
          "Uncorrected",
          "Corrected",
        ];
      }
    },
    computedHeaders() {
      return this.headers.map((header) => ({
        ...header,
        unitKey:
          header.label === "Flashed Gas" ||
          header.label === "Flashed Liquid" ||
          header.label === "Overall"
            ? this.unitKey
            : null,
      }));
    },
  },
  watch: {
    sampleData: {
      immediate: true,
      handler(newData) {
        this.updateTableData(newData);
      },
    },
    isCheckedPercentage() {
      this.updateTableData(this.sampleData);
    },
    isChecked() {
      this.updateTableData(this.sampleData);
    },
  },
  methods: {
    updateTableData(data) {
      if (!data || !data.correctedComposition || !data.originalComposition) {
        // If data or required properties are missing, clear the table or handle it gracefully
        this.tableData = [];
        return;
      }
      const combinedData = data.correctedComposition.composition.map((correctedItem) => {
        const originalItem = data.originalComposition.composition.find(
          (item) => item.component === correctedItem.component
        );

        const calculateDelta = (corrected, uncorrected) => {
          if (corrected !== undefined && uncorrected !== undefined && uncorrected != 0) {
            const delta = (((corrected - uncorrected) / uncorrected) * 100).toFixed(1);
            return delta + " %";
          }
          return "-";
        };

        const flashedGasCorrected = this.isCheckedPercentage
          ? correctedItem.stockTankGas_MoleFraction?.toFixed(3)
          : correctedItem.stockTankGas_MassFraction?.toFixed(3);
        const flashedGasUncorrected = this.isCheckedPercentage
          ? originalItem?.stockTankGas_MoleFraction?.toFixed(3)
          : originalItem?.stockTankGas_MassFraction?.toFixed(3);
        const flashedGasDelta = calculateDelta(
          flashedGasCorrected,
          flashedGasUncorrected
        );

        const flashedLiquidCorrected = this.isCheckedPercentage
          ? correctedItem.reservoirLiquid_MoleFraction?.toFixed(3)
          : correctedItem.reservoirLiquid_MassFraction?.toFixed(3);
        const flashedLiquidUncorrected = this.isCheckedPercentage
          ? originalItem?.reservoirLiquid_MoleFraction?.toFixed(3)
          : originalItem?.reservoirLiquid_MassFraction?.toFixed(3);
        const flashedLiquidDelta = calculateDelta(
          flashedLiquidCorrected,
          flashedLiquidUncorrected
        );

        const overallCorrected = this.isCheckedPercentage
          ? correctedItem.overall_MoleFraction?.toFixed(3)
          : correctedItem.overall_MassFraction?.toFixed(3);
        const overallUncorrected = this.isCheckedPercentage
          ? originalItem?.overall_MoleFraction?.toFixed(3)
          : originalItem?.overall_MassFraction?.toFixed(3);
        const overallDelta = calculateDelta(overallCorrected, overallUncorrected);

        return {
          component: correctedItem.component || "-",
          mw: correctedItem.mw !== undefined ? correctedItem.mw.toFixed(2) : "-",
          density:
            correctedItem.density !== undefined ? correctedItem.density.toFixed(3) : "-",
          flashedGas: {
            corrected: flashedGasCorrected || "-",
            uncorrected: flashedGasUncorrected || "-",
            delta: flashedGasDelta,
          },
          flashedLiquid: {
            corrected: flashedLiquidCorrected || "-",
            uncorrected: flashedLiquidUncorrected || "-",
            delta: flashedLiquidDelta,
          },
          overall: {
            corrected: overallCorrected || "-",
            uncorrected: overallUncorrected || "-",
            delta: overallDelta,
          },
        };
      });

      this.tableData = combinedData;
    },
    getCellContent(fractionData) {
      let arrowClass = "";
      let arrowIcon = "";

      if (fractionData.corrected > fractionData.uncorrected) {
        arrowClass = "arrow-green";
        arrowIcon = '<i class="fa-solid fa-arrow-up"></i>';
      } else if (fractionData.corrected < fractionData.uncorrected) {
        arrowClass = "arrow-red";
        arrowIcon = '<i class="fa-solid fa-arrow-down"></i>';
      }

      // When isChecked is true, show delta value
      var finalData = fractionData.corrected;
      if (this.isChecked) {
        finalData = fractionData.delta;
      }

      // Return the corrected value and conditionally add the arrow icon with span
      return `${
        arrowIcon
          ? `&nbsp; <span class="${arrowClass}">${finalData} ${arrowIcon}</span>`
          : ""
      }`;
    },
  },
};
</script>
