<template>
  <div>
    <DynamicModalComponent
      ref="dynamicModal"
      @correction-modal="correctionModal"
    />
    <CorrectionModalComponent ref="correctionModal"/>
    <div class="main-page">
      <div class="navbar-wrapper">
        <NavbarComponent />
      </div>
      <div class="container-fluid main-wrapper">
        <div class="row no-gutters">
          <div class="col-12">
            <div v-if="loading" class="loader-overlay">
              <CircularLoader />
            </div>
            <ImportFromDatabaseComponent
              @open-sample-correction-with-file="openSampleCorrectionWithFile"
            />
            <SampleCorrectionComponent 
              ref="sampleCorrectionModal" 
              @show-dynamic-modal="showDynamicModal" 
              @stop-loading="stopLoading"
              @start-loading="startLoading"
            />
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import NavbarComponent from "../components/Layout/NavbarComponent.vue";
import FooterComponent from "../components/Layout/FooterComponent.vue";
import ImportFromDatabaseComponent from "../components/AICorrection/ImportFromDatabaseComponent.vue";
import CircularLoader from "../components/Loader/CircularLoader.vue";
import DynamicModalComponent from "../components/Common/DynamicModalComponent.vue";
import SampleCorrectionComponent from "../components/AICorrection/SampleCorrectionComponent.vue";
import CorrectionModalComponent from "../components/AICorrection/CorrectionModalComponent.vue";

export default {
  name: "AICorrection",
  components: {
    NavbarComponent,
    FooterComponent,
    ImportFromDatabaseComponent,
    CircularLoader,
    DynamicModalComponent,
    SampleCorrectionComponent,
    CorrectionModalComponent,
  },
  data() {
    return {
      loading: false,
      sampleId:'',
      sampleData:[],
    };
  },
  mounted() {
    if (this.$route.params.id) {
        this.openSampleCorrection(this.$route.params.id);
    } else {
        console.log('No requestID, proceeding without it');
    }
  },
  methods: {
    openSampleCorrection(requestID) {
      this.loading = true;
      this.$refs.sampleCorrectionModal.openModal(requestID);
    },
    openSampleCorrectionWithFile(file)
    {
      this.loading = true;
      this.$refs.sampleCorrectionModal.openModalWithFile(file);
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    showDynamicModal(sampleData,sampleId,modalIcon,modalTitle,modalText,modalTextClass,modalButtons) {
      this.sampleId=sampleId;
      this.sampleData=sampleData;
      this.$refs.dynamicModal.openModal(modalIcon,modalTitle,modalText,modalTextClass,modalButtons);
    },
    correctionModal()
    {
      this.$refs.correctionModal.openModal(this.sampleData,this.sampleId);
    }
  },
};
</script>
