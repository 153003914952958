<template>
  <div>
    <div ref="plotContainer" style="height: 500px;"></div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import Plotly from 'plotly.js-dist';
import axios from 'axios';

export default {
  name: 'ChartDataComponent',
  props: {
    filterData: Object,
  },
  setup(props, { emit }) {  // Use destructuring to get emit
    const plotContainer = ref(null);

    // Method to plot data
    const plotData = (correctedFluids, uncorrectedFluids, correctedClusterCenters, uncorrectedClusterCenters) => {
      if (!plotContainer.value) {
        console.error("Plot container not available.");
        return;
      }
      const traces = [];

      // Determine which datasets to show based on isCorrected flag
      const showCorrected = props.filterData.isCorrected === true || props.filterData.isCorrected === '';
      const showUncorrected = props.filterData.isCorrected === false || props.filterData.isCorrected === '';

      // Add corrected fluids if allowed
      if (showCorrected && correctedFluids.length) {
        traces.push({
          x: correctedFluids.map(d => d.c36plus_MassFraction),
          y: correctedFluids.map(d => d.c36plus_Density),
          z: correctedFluids.map(d => d.c36plus_MW),
          mode: 'markers',
          type: 'scatter3d',
          marker: {
            size: 3,
            color: 'lightblue',
            opacity: 0.8
          },
          name: 'Corrected Fluids'
        });
      }

      // Add uncorrected fluids if allowed
      if (showUncorrected && uncorrectedFluids.length) {
        traces.push({
          x: uncorrectedFluids.map(d => d.c36plus_MassFraction),
          y: uncorrectedFluids.map(d => d.c36plus_Density),
          z: uncorrectedFluids.map(d => d.c36plus_MW),
          mode: 'markers',
          type: 'scatter3d',
          marker: {
            size: 3,
            color: 'lightred',
            opacity: 0.8
          },
          name: 'Uncorrected Fluids'
        });
      }

      // Add corrected cluster centers if allowed
      if (showCorrected && correctedClusterCenters.length) {
        traces.push({
          x: correctedClusterCenters.map(d => d.c36plus_MassFraction),
          y: correctedClusterCenters.map(d => d.c36plus_Density),
          z: correctedClusterCenters.map(d => d.c36plus_MW),
          mode: 'markers',
          type: 'scatter3d',
          marker: {
            size: 8,
            color: 'darkblue',
            opacity: 1
          },
          name: 'Corrected Cluster Centers'
        });
      }

      // Add uncorrected cluster centers if allowed
      if (showUncorrected && uncorrectedClusterCenters.length) {
        traces.push({
          x: uncorrectedClusterCenters.map(d => d.c36plus_MassFraction),
          y: uncorrectedClusterCenters.map(d => d.c36plus_Density),
          z: uncorrectedClusterCenters.map(d => d.c36plus_MW),
          mode: 'markers',
          type: 'scatter3d',
          marker: {
            size: 8,
            color: 'red',
            opacity: 1
          },
          name: 'Uncorrected Cluster Centers'
        });
      }

      const layout = {
        scene: {
          xaxis: { title: 'C36+ wt%', range: [0, 100] },
          yaxis: { title: 'C36+ Density', range: [0, 100] },
          zaxis: { title: 'C36+ MW', range: [0, 100] },
        },
        margin: { l: 0, r: 0, b: 0, t: 0 },
        legend: {
          x: 0.8,
          y: 0.9,
        }
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d']
      };

      // Safeguard against any potential rendering issues
      try {
        Plotly.newPlot(plotContainer.value, traces, layout, config);
      } catch (err) {
        console.error("Error rendering plot:", err);
      }
    };

    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(`samples/cluster-data`, {
          params: {
            asset: props.filterData.asset.length > 0 ? JSON.stringify(props.filterData.asset) : '',
            field: props.filterData.field.length > 0 ? JSON.stringify(props.filterData.field) : '',
            well: props.filterData.well.length > 0 ? JSON.stringify(props.filterData.well) : '',
            reservoir: props.filterData.reservoir.length > 0 ? JSON.stringify(props.filterData.reservoir) : '',
            sampleType: props.filterData.sampleType.length > 0 ? JSON.stringify(props.filterData.sampleType) : '',
          }
        });

        const data = response.data.data;

        const correctedFluids = [];
        const uncorrectedFluids = [];
        const correctedClusterCenters = [];
        const uncorrectedClusterCenters = [];

        // Separate data based on isAnchor and corrected/uncorrected
        if (data.fluids) {
          data.fluids.forEach(fluid => {
            correctedFluids.push(...fluid.corrected);
            uncorrectedFluids.push(...fluid.uncorrected);
          });
        }

        if (data.clusterCenters) {
          data.clusterCenters.forEach(cluster => {
            correctedClusterCenters.push(...cluster.corrected);
            uncorrectedClusterCenters.push(...cluster.uncorrected);
          });
        }

        plotData(correctedFluids, uncorrectedFluids, correctedClusterCenters, uncorrectedClusterCenters);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Watch for changes in props and recall API
    watch(
      () => props.filterData,
      async (newValue, oldValue) => {
        emit('start-loading');
        await fetchData();
        emit('stop-loading');
      },
      { deep: true }
    );

    // Initial fetch and plot on mount
    onMounted(() => {
      fetchData();
    });

    return { plotContainer };
  }
}
</script>
