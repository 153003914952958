<template>
  <div ref="histogram2d"></div>
</template>

<script>
import Plotly from 'plotly.js-dist';

export default {
  name: '2DHistogram',
  mounted() {
    this.drawHistogram2D();
  },
  methods: {
    drawHistogram2D() {
      const trace = {
        x: [1, 2, 2, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 5],
        y: [2, 3, 2, 4, 3, 3, 5, 4, 4, 3, 6, 5, 4, 3, 2],
        type: 'histogram2d'
      };

      const layout = {
        title: '2D Histogram',
        xaxis: { title: 'X Axis' },
        yaxis: { title: 'Y Axis' },
        height: 400,
        width: 500,
      };

      Plotly.newPlot(this.$refs.histogram2d, [trace], layout);
    }
  }
};
</script>