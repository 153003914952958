<template>
  <div class="main-page">
    <!-- <h1>404 Not Found / 401 Unauthorized</h1> -->
    <p>The page you are looking for does not exist or you are not allowed to access this page.</p>
  </div>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>