<template>
  <div class="loader-container">
    <svg class="circular-loader" viewBox="0 0 50 50">
      <circle
        class="loader-path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="#E5F1FB"
        stroke-width="5"
      />
      <circle
        class="loader-progress"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="#3498db"
        stroke-width="5"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'CircularLoader'
}
</script>