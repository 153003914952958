<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#dynamicModal"
      ref="openModalDynamic"
    ></button>

    <div
      class="modal fade"
      id="dynamicModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <img v-if="icon" :src="icon" alt="modal-icon"/>
            <p :class="textClass">{{ text }}</p>
          </div>
          <div class="modal-footer">
            <button
              v-for="button in buttons"
              :key="button.text"
              :class="'btn ' + button.class"
              @click="handleButtonClick(button.action)"
            >
              {{ button.text }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import $ from "jquery";
export default {
  name: "DynamicModalComponent",
  data(){
    return{
      error_icon: require("../../assets/images/modal/error.png"),
      success_icon: require("../../assets/images/modal/success.png"),
      icon:'',
      title:'',
      text:'',
      buttons:'',
      textClass:'',
    } 
  },
  methods: {
    openModal(modalIcon,modalTitle,modalText,modalTextClass,modalButtons) {
      var elem = this.$refs.openModalDynamic;
      if (elem) {
        elem.click();
        this.icon=(modalIcon=='success')?this.success_icon:this.error_icon,
        this.title=modalTitle;
        this.text=modalText;
        this.textClass=modalTextClass;
        this.buttons=modalButtons;
      }
    },
    handleButtonClick(action) {
      if (action === "aiCorrection") {
        $("#dynamicModal").modal("hide");
        this.$emit("correction-modal");
      } else if (action === "closeModal" || action == "finish") {
        $("#dynamicModal").modal("hide");
        this.$router.push("/");
      }
    },
  },
};
</script>