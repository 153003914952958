<template>
  <div class="chart-data-assets">
    <div class="top-content">
      <h6>Assets</h6>
      <IconComponent />
    </div>
    <div :ref="chartId"></div>
  </div>
</template>

<script>
import IconComponent from "./IconComponent.vue";
import Plotly from "plotly.js-dist";

export default {
  name: "AssetComponent",
  components: { IconComponent },
  props: {
    assetData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      chartId: "assetPieChart",
      total: 0,
    };
  },
  watch: {
    assetData: {
      handler(newData) {
        if (newData && newData.length > 0) {
          this.total = newData.reduce((acc, item) => acc + item.totalCorrected, 0);
          this.drawChart();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    drawChart() {
      const values = this.assetData.map(item => item.totalCorrected);
      const labels = this.assetData.map(item => item.asset);

      const data = [
        {
          values: values,
          labels: labels,
          type: "pie",
          hole: 0.7,
          textinfo: "none",
          marker: {
            colors: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"],
          },
        },
      ];

      const layout = {
        annotations: [
          {
            font: { size: 24 },
            showarrow: false,
            text: `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
            x: 0.5,
            y: 0.5,
          },
        ],
        showlegend: true,
        legend: {
          orientation: "v",
          y: 0.5,
        },
        height: 192,
        margin: { t: 0, b: 0, l: 0, r: 0 },
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
      };

      Plotly.newPlot(this.$refs[this.chartId], data, layout, config);
      
      this.updateTotalOnLegendClick();
    },
    updateTotalOnLegendClick() {
      this.$refs[this.chartId].on('plotly_restyle', (eventData) => {
        const updatedVisibility = eventData[0]['visible'];

        // Recalculate the total based on the visibility of each slice
        let updatedTotal = 0;
        updatedVisibility.forEach((isVisible, index) => {
          if (isVisible || isVisible === true) {
            updatedTotal += this.assetData[index].totalCorrected;
          }
        });

        this.total = updatedTotal;

        // Update the annotation with the new total
        Plotly.relayout(this.$refs[this.chartId], {
          'annotations[0].text': `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
        });
      });
    },
  },
};
</script>
