<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#FilterSave"
      ref="openModalFilterSave"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="FilterSave">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Name you filter</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="row no-gutters">
                <div class="col">
                    <input v-model="name" class="form-control" type="text" placeholder="Enter your filter name">
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" data-dismiss="modal" aria-label="Close" class="btn cancel" @click="reopenParentModal">Cancel</button>
            <button type="button" class="btn" @click="saveFilter" :disabled="loading">
              <span v-if="loading">
                <span>Saving...</span>
              </span>
              <span v-else class="export-sec">
                <span>Save</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';
export default {
  name: "SaveFilterModalComponent",
  data() {
    return {
      name: "",
      filterData:{},
      loading: false
    };
  },
  methods: {
    openModal(filterData) {
      this.filterData=filterData;
      var elem = this.$refs.openModalFilterSave;
      if (elem) {
        elem.click();
      }
    },
    reopenParentModal() {
      this.$emit('reopenParentModal');
    },
     saveFilter()
     {
       this.loading = true
      if(this.name)
        {
          const filterData = {
              Data: {
                Filter: {
                  name: this.name,
                  asset: this.filterData.asset,
                  field: this.filterData.field,
                  well: this.filterData.well,
                  reservoir: this.filterData.reservoir,
                  sampletype: this.filterData.sampleType,
                  sampleName: this.filterData.sample,
                  isCorrected: this.filterData.ai_corrected?(this.filterData.ai_corrected=='Yes'?true:false):null,
                }
              }
            };

            axios.post(`filters`, filterData)
              .then(response => {
                this.name='';
                this.filterData={};
                this.$emit("fetch-apply-filter");
                this.closeModal();
                // this.reopenParentModal();
              })
              .catch(error => {
                console.error('Error updating filter:', error);
              })
              .finally(() => {
                this.loading = false;
              })
        }
     },
    closeModal() {
      $('#FilterSave').modal('hide');
    }
  },
};
</script>
