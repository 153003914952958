<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#ImportData"
      ref="openModalImportData"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="ImportData">
      <div
        class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
      >
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-top">
              <h5 class="modal-title" id="staticBackdropLabel">
                Import Sample
              </h5>
              <div>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="row no-gutters w-100 saved-filter-option">
              <div class="col">
                <div class="custom-file">
                  <input type="file" class="custom-file-input" />
                  <label class="custom-file-label">Choose file...</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <div class="table-container">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th v-for="header in tableHeaders" :key="header">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in tableData" :key="item.idNumber">
                      <td>{{ item.asset }}</td>
                      <td>{{ item.field }}</td>
                      <td>{{ item.reservoir }}</td>
                      <td>{{ item.well }}</td>
                      <td>{{ item.sample }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn"
              @click="importSample()"
              :disabled="loading"
            >
              <span v-if="loading">
                <span>Importing...</span>
              </span>
              <span v-else class="export-sec">
                <span>Import</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "ImportModalComponent",
  data() {
    return {
      save: require("../../assets/images/modal/disk 1.png"),
      reset: require("../../assets/images/modal/refresh-2.png"),
      loading: false,
      tableHeaders: ["Asset", "Field", "Reservoir", "Well", "Sample"],
      tableData: [
        {
          idNumber: "G001",
          asset: "Asset 1",
          field: "Field 1",
          reservoir: "Reservoir 1",
          well: "Well 1",
          sample: "Sample #123",
          anchorPointData: true,
          allCorrected: true,
        },
        {
          idNumber: "G002",
          asset: "Asset 2",
          field: "Field 2",
          reservoir: "Reservoir 1",
          well: "Well 2",
          sample: "Sample #144",
          anchorPointData: false,
          allCorrected: true,
        },
        {
          idNumber: "G003",
          asset: "Asset 3",
          field: "Field 3",
          reservoir: "Reservoir 1",
          well: "Well 3",
          sample: "Sample #234",
          anchorPointData: true,
          allCorrected: false,
        },
        // Add more data as needed
      ],
    };
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalImportData;
      if (elem) {
        elem.click();
      }
    },
    importSample() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        $("#ImportData").modal("hide");
      }, 2000);
    },
  },
};
</script>
