<template>
  <div>
    <ToggleData @toggle-change="onToggleChange" />
    <div class="table-container">
      <table class="table">
        <thead>
          <tr class="header-row">
            <th colspan="1">Component</th>
            <th colspan="1">MW</th>
            <th colspan="1">Density</th>
            <th colspan="2">Flashed Gas</th>
            <th colspan="2">Flashed Liquid</th>
            <th colspan="2">Overall</th>
          </tr>
          <tr class="units-row">
            <th></th>
            <th></th>
            <th></th>
            <th>mol%</th>
            <th>wt%</th>
            <th>mol%</th>
            <th>wt%</th>
            <th>mol%</th>
            <th>wt%</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            :class="{ 'even-row': index % 2 === 1 }"
          >
            <td>{{ item.component }}</td>
            <td>{{ toFixedValuetwo(item.mw) }}</td>
            <td>{{ toFixedValuethree(item.density) }}</td>
            <td>{{ toFixedValuethree(item.stockTankGas_MoleFraction) }}</td>
            <td>{{ toFixedValuethree(item.stockTankGas_MassFraction) }}</td>
            <td>{{ toFixedValuethree(item.stockTankOil_MoleFraction) }}</td>
            <td>{{ toFixedValuethree(item.stockTankOil_MassFraction) }}</td>
            <td>{{ toFixedValuethree(item.reservoirLiquid_MoleFraction) }}</td>
            <td>{{ toFixedValuethree(item.reservoirLiquid_MassFraction) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ToggleData from "./ToggleDataComponent.vue";

export default {
  name: "TableDataComponent",
  components: { ToggleData },
  props: {
    sampleData: Object
  },
  data() {
    return {
      useCorrectedData: true // Default to showing AI-corrected data
    };
  },
  computed: {
    tableData() {
      if (this.useCorrectedData) {
        return this.sampleData?.correctedComposition?.composition || [];
      } else {
        return this.sampleData?.originalComposition?.composition || [];
      }
    }
  },
  methods: {
    toFixedValuetwo(value) {
      return value ? parseFloat(value).toFixed(2) : value;
    },
    toFixedValuethree(value) {
      return value ? parseFloat(value).toFixed(3) : value;
    },
    toFixedValueFour(value) {
      return value ? parseFloat(value).toFixed(4) : value;
    },
    onToggleChange(isChecked) {
      this.useCorrectedData = !isChecked;
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
