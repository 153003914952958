<template>
  <div class="toggle-select-wrapper">
    <div class="toggle-container">
      <span class="toggle-label">Data Comparison</span>
      <label class="toggle">
        <input type="checkbox" v-model="localChecked" />
        <span class="slider round"></span>
      </label>
      <span class="toggle-label">% Delta</span>
    </div>
    <div class="toggle-container" :class="toggleContainerClass">
      <span class="toggle-label">wt%</span>
      <label class="toggle">
        <input type="checkbox" v-model="localCheckedPercentage"  :disabled="localChecked"/>
        <span class="slider round"></span>
      </label>
      <span class="toggle-label">Mole%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToggleModalButtons",
  props: {
    checkedPercentage: {
      type: Boolean
    },
    checked: {
      type: Boolean
    }
  },
   data() {
    return {
      localCheckedPercentage: false,
      localChecked: false,
    };
  },
   computed: {
    toggleContainerClass() {
      return {
        'toggle-container': true,
        'disabled': this.localChecked
      };
    }
  },
  watch: {
    checkedPercentage(newVal) {
      this.localCheckedPercentage = newVal;
    },
    localCheckedPercentage(newVal) {
      this.$emit('update:checkedPercentage', newVal);
    },

    checked(newVal) {
      this.localChecked = newVal;
    },
    localChecked(newVal) {
      this.$emit('update:checked', newVal);
    }
  }
};
</script>