<template>
  <div>
    <div id="scatter3DPlot" style="width: 100%; height: 157px;"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist';

export default {
  mounted() {
    this.create3DScatterPlot();
  },
  methods: {
    create3DScatterPlot() {
      const trace2 = {
        x: Array.from({ length: 100 }, (_, i) => Math.random() * 1000),
        y: Array.from({ length: 100 }, (_, i) => Math.random() * 1000),
        z: Array.from({ length: 100 }, (_, i) => Math.random() * 1000),
        mode: 'markers',
        marker: {
          size: 5,
          color: Array.from({ length: 100 }, (_, i) => Math.random() * 1000),
          colorscale: 'Viridis'
        },
        type: 'scatter3d'
      };

      const layout = {
        title: '3D Scatter Plot',
        scene: {
          xaxis: { title: 'X Axis' },
          yaxis: { title: 'Y Axis' },
          zaxis: { title: 'Z Axis' }
        }
      };

      Plotly.newPlot('scatter3DPlot', [trace2], layout);
    }
  }
};
</script>

<style scoped>
#scatter3DPlot {
  width: 100%;
  height: 100%;
}
</style>
