<template>
  <div class="main-page">
    <div class="navbar-wrapper">
      <NavbarComponent />
    </div>
    <div class="container-fluid main-wrapper">
      <div class="row no-gutters">
        <div class="col-12 col-xl-8 custom-padding no-padding-left">
          <div class="chart-wrapper">
            <ChartComponent 
            :filterData="filterData"
            />
          </div>
        </div>
        <div class="col-12 col-xl-4 no-padding-right">
          <div class="asset-field-wrapper">
            <AssetComponent :assetData="assetData" />
            <FieldComponent :fieldData="fieldData" />
          </div>
        </div>
      </div>
      <div class="row no-gutters"  v-if="hasPermission('ManageUsers')">
        <TableComponent @apply-filter="applyFilter"  />
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from "axios";
import AssetComponent from "../components/Common/AssetComponent.vue";
import ChartComponent from "../components/Common/ChartComponent.vue";
import NavbarComponent from "../components/Layout/NavbarComponent.vue";
import FieldComponent from "../components/Common/FieldComponent.vue";
import TableComponent from "../components/SampleSummary/TableComponent.vue";
import FooterComponent from "@/components/Layout/FooterComponent.vue";

export default {
  name: "SampleSummary",
  components: {
    NavbarComponent,
    ChartComponent,
    AssetComponent,
    FieldComponent,
    TableComponent,
    FooterComponent,
  },
  data() {
    return {
      assetData: [],
      fieldData: [],
      filterData: {
        asset: [],
        field: [],
        reservoir:[],
        well: [],
        sample:[],
        sampleType:[],
        isCorrected:'',
        isAnchorPoint:'',
        filterType:'',
      },
      expires: '',
      firstName: '',
      lastName: '',
      role: '',
      token: '',
      username: ''
    };
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapGetters(['hasPermission'])
  },
  methods: {
    applyFilter(filterData )
    {
      this.filterData=filterData;
    },
    fetchData() {
      axios.get("samples/metrics")
        .then((response) => {
          const data = response.data.data;
          this.assetData = data.filter(item => item.scope === 'asset');
          this.fieldData = data.filter(item => item.scope === 'field');
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
};
</script>
