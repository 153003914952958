<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <span class="navbar-brand">
      <img :src="logo" alt="logo" />
    </span>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
          <a class="nav-link">
            <div class="img-wrap-first">
              <img :src="task" alt="dashboard" />
            </div>
            <span>Dashboard</span>
          </a>
          <div class="img-wrap-last">
            <img :src="arrow_down" alt="arrow_down" />
          </div>
        </li> -->
        <li class="nav-item">
          <router-link to="/" exact class="nav-link">
          <div class="img-wrap-first">
            <img :src="category" alt="category" />
          </div>
          <span>Compositions Correction</span>
          </router-link>
          <!-- <div class="img-wrap-last">
            <img :src="arrow_down" alt="arrow_down" />
          </div> -->
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <div class="img-wrap-user">
            <img :src="user_image" alt="img" />
          </div>
          <div>
            <div class="username">{{ userData?.firstName }} {{ userData?.lastName }}</div>
            <div class="designation">{{ userData?.role }}</div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import defaultUserImage from "../../assets/images/navbar/user.png";
export default {
  name: "NavbarComponent",
  data() {
    return {
      logo: require("../../assets/images/navbar/logo.png"),
      task: require("../../assets/images/navbar/task.png"),
      arrow_down: require("../../assets/images/navbar/arrow-down.png"),
      category: require("../../assets/images/navbar/category.png"),
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user'
    }),
    user_image() {
      const imageURL = this.userData?.imageUrl;
      const baseUrl = process.env.VUE_APP_PROFILE_URL;
      return imageURL ? `${baseUrl}${imageURL}` : defaultUserImage;
    },
  },
};
</script>