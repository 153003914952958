<template>
  <div>
    <div id="scatterPlot" style="width: 100%; height: 157px;"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist';

export default {
  mounted() {
    this.createScatterPlot();
  },
  methods: {
    createScatterPlot() {
      const trace1 = {
        x: Array.from({ length: 100 }, (_, i) => i * 5),
        y: Array.from({ length: 100 }, (_, i) => 50 + Math.sin(i / 5) * 10),
        mode: 'markers',
        type: 'scatter',
        marker: { color: 'orange' }
      };

      const layout = {
        title: '2D Scatter Plot with Zones',
        xaxis: { title: 'X Axis' },
        yaxis: { title: 'Y Axis' },
        shapes: [
          {
            type: 'rect',
            x0: 0,
            x1: 500,
            y0: 60,
            y1: 100,
            fillcolor: 'red',
            opacity: 0.2,
            line: { width: 0 }
          },
          {
            type: 'rect',
            x0: 0,
            x1: 500,
            y0: 40,
            y1: 60,
            fillcolor: 'green',
            opacity: 0.2,
            line: { width: 0 }
          },
          {
            type: 'rect',
            x0: 0,
            x1: 500,
            y0: 0,
            y1: 40,
            fillcolor: 'blue',
            opacity: 0.2,
            line: { width: 0 }
          }
        ]
      };

      Plotly.newPlot('scatterPlot', [trace1], layout);
    }
  }
};
</script>

<style scoped>
#scatterPlot {
  width: 100%;
  height: 100%;
}
</style>
