<template>
  <div class="login-image">
    <img :src="login_image" alt="login-image" />
  </div>
</template>

<script>
export default {
  name: "AuthLeftComponent",
  data() {
    return {
      login_image: require("../../assets/images/login/login-image.png"),
    };
  },
};
</script>