<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#aiCorrectionModal"
      ref="openModalCorrection"
    ></button>

    <div
      class="modal fade"
      id="aiCorrectionModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-body p-0 mr-0">
            <div class="container-fluid ai-correction">
              <div class="row no-gutters">
                <div class="col-12 col-lg-8 col-xl-9 pr-3">
                  <div class="chart-content-top">
                  </div>
                  <div class="chart-info text-center">
                    <ChartDataComponent  @start-loading="startLoading" @stop-loading="stopLoading" :filterData="filterData"/>
                  </div>
                </div>
                <div
                  class="col-12 col-lg-4 col-xl-3 px-lg-3 pr-3"
                >
                  <ImportSampleComponent 
                    @start-loading="startLoading" 
                    @stop-loading="stopLoading" 
                    @show-dynamic-modal="showDynamicModal" 
                    :requestID="requestID" 
                    :selectedFile="selectedFile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartDataComponent from "../Common/ChartDataComponent.vue";
import ImportSampleComponent from "./ImportSampleComponent.vue";
import $ from "jquery";

export default {
  name: "SampleCorrection",
  components: { ChartDataComponent, ImportSampleComponent },
  data() {
    return {
      sample:{},
      requestID:'',
      selectedFile:'',
      filterData:{ 
        asset: [],
        field: [],
        reservoir:[],
        well: [],
        sample:[],
        sampleType:[],
        isCorrected:'',
      },
      selectSampleData:{},
      arrow_down: require("../../assets/images/navbar/arrow-down.png"),
    };
  },
  methods: {
    startLoading()
    {
      this.$emit('start-loading');
    },
    stopLoading()
    {
      this.$emit('stop-loading');
    },
    showDynamicModal(sampleData,sampleId,modalIcon,modalTitle,modalText,modalTextClass,modalButtons) {
       $("#aiCorrectionModal").modal("hide");
      this.$emit("show-dynamic-modal",sampleData,sampleId,modalIcon,modalTitle,modalText,modalTextClass,modalButtons);
    },
    openModal(requestID) {
      var elem = this.$refs.openModalCorrection;
      if (elem) {
        this.requestID=requestID;
        elem.click();
      }
    },

    openModalWithFile(selectedFile)
    {
      var elem = this.$refs.openModalCorrection;
      if (elem) {
        this.selectedFile=selectedFile;
        elem.click();
      }
    }
  },
};
</script>