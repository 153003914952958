<template>
  <div class="chart-data-bottom">
    <div v-if="chartType=='all'" class="form-check d-flex align-items-center">
      <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="flashedGas" @change="updateSampleData"
        v-model="filterData.flashed_gas" />
      <label class="form-check-label flashedGas" for="flashedGas">
        Flashed Gas
      </label>
    </div>
    <div v-if="chartType=='all' || chartType=='sto'" class="form-group">
      <h6>STO Composition</h6>
      <div class="form-check d-flex align-items-center">
        <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="stoCorrectedComposition"
          @change="updateSampleData" v-model="filterData.sto_corrected_composition" />
        <label class="form-check-label stoCorrectedComposition" for="stoCorrectedComposition">
          Corrected Composition
        </label>
      </div>
      <div class="form-check d-flex align-items-center mt-2">
        <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="stoUncorrectedComposition"
          @change="updateSampleData" v-model="filterData.sto_uncorrected_composition" />
        <label class="form-check-label stoUncorrectedComposition" for="stoUncorrectedComposition">
          Uncorrected Composition
        </label>
      </div>
    </div>
    <div v-if="chartType=='all'" class="form-group">
      <h6>Separator Liquid</h6>
      <div class="form-check d-flex align-items-center">
        <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="separatorLiquidCorrected"
          @change="updateSampleData" v-model="filterData.separator_liquid_corrected" />
        <label class="form-check-label separatorLiquidCorrected" for="separatorLiquidCorrected">
          Corrected Composition
        </label>
      </div>
      <div class="form-check d-flex align-items-center mt-2">
        <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="separatorLiquidUncorrected"
          @change="updateSampleData" v-model="filterData.separator_liquid_uncorrected" />
        <label class="form-check-label separatorLiquidUncorrected" for="separatorLiquidUnCorrected">
          Uncorrected Composition
        </label>
      </div>
    </div>
    <div v-if="chartType=='all'" class="form-check d-flex align-items-center">
      <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="separatorGas"
        v-model="filterData.separator_gas" @change="updateSampleData" />
      <label class="form-check-label separatorGas" for="separatorGas">
        Separator Gas
      </label>
    </div>
    <div v-if="chartType=='all'" class="form-group">
      <h6>Reservoir Fluid</h6>
      <div class="form-check d-flex align-items-center">
        <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="reservoirFluidCorrected"
          @change="updateSampleData" v-model="filterData.reservoir_fluid_corrected" />
        <label class="form-check-label reservoirFluidCorrected" for="reservoirFluidCorrected">
          Corrected Composition
        </label>
      </div>
      <div class="form-check d-flex align-items-center mt-2">
        <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="reservoirFluidUncorrected"
          @change="updateSampleData" v-model="filterData.reservoir_fluid_uncorrected" />
        <label class="form-check-label reservoirFluidUncorrected" for="reservoirFluidUncorrected">
          Uncorrected Composition
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChartBottomComponent",
  data() {
    return {
      filterData: {
        flashed_gas: true,
        sto_corrected_composition: true,
        sto_uncorrected_composition: true,
        separator_liquid_corrected: true,
        separator_liquid_uncorrected: true,
        separator_gas: true,
        reservoir_fluid_corrected: true,
        reservoir_fluid_uncorrected: true,
      },
    };
  },
  props: {
    chartType: String,
    showSelection:Boolean
  },
  methods: {
    updateSampleData() {
      this.$emit("updateDataOnCheck", this.filterData);
    },
  },
};
</script>
