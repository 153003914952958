<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#workFlowModal"
      ref="openModalWorkFlow"
    ></button>
    <!-- Modal Structure -->
    <div class="modal fade" id="workFlowModal" tabindex="-1" role="dialog" aria-labelledby="workFlowModalCenterTitle" aria-hidden="true" >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <div class="ai-correction-sec">
              <h6>AI Correction workflow</h6>
              <div class="stepper">
                <div v-for="(step, index) in steps" :key="index" :class="['step', { active: index <= currentStep }]">
                  <div :class="['circle', { active: index <= currentStep }]" @click="setStep(index)">
                    {{ index + 1 }}
                  </div>
                  <div :class="['label', { active: index <= currentStep }]" @click="setStep(index)">
                    {{ step.label }}
                  </div>
                  <div v-if="index < steps.length - 1" class="line"></div>
                </div>
              </div>
            </div>
            <div class="ai-step-wrapper">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div :class="stepInfoClass" v-if="currentStepText">
                <div class="ai-step-info">
                  <div class="top">
                    <div :class="['circle', { active: currentStep <= steps.length - 1 }]">
                      {{ currentStep + 1 }}
                    </div>
                    <h6>
                      {{ currentStepText.label }}
                    </h6>
                  </div>
                  <p v-html="currentStepText.text"></p>
                </div>

                  <div v-if="currentStep === 0" class="chart-container">
                    <free-style-shimmer
                     v-if="!correctionBarPlot"
                      :is-loading="true"
                      height="525px"
                      width="625px"
                      border-radius="16px"
                      color="#bdbdbd"
                    />
                    <iframe v-else :srcdoc="correctionBarPlot" width="100%" height="100%" frameborder="0"></iframe>
                  </div>

                <div v-else-if="currentStep === 3" class="table-container">
                  <div v-html="currentStepText.tableHtml"></div>
                </div>

                <div v-else-if="currentStep === 4 || currentStep === 5" class="container">
                  <ScatterChartForAI
                    :localSampleData="aiLocalSampleData" 
                    :yAxisScale="yAxisScale" 
                  />
                </div>

                <div v-else class="ai-step-image">
                  <img :src="currentStepText.image" alt="Step Image" />
                </div>

              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div>
              <button @click="previousStep" v-if="currentStep > 0" class="btn cancel mr-3">
                <i class="fas fa-chevron-left"></i>
                <span> Previous </span>
              </button>
            </div>
            <div>
              <button @click="nextStep" :disabled="currentStep >= steps.length - 1" class="btn main-btn">
                <span> Next </span>
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import axios from 'axios';
import TwoDScatterChart from "@/components/Charts/2DScatterChart.vue";
import ThreeDScatterChart from "@/components/Charts/3DScatterChart.vue";
import ScatterChartForAI from "@/components/Charts/ScatterChartForAI.vue";
import TwoDHistogram from "../../Charts/2DHistogram.vue";
import { getFilteredData } from '@/utils/useFilteredData.js'; 

export default {
  name: "AIWorkFlowModalComponent",
  components: {
    TwoDScatterChart,
    ThreeDScatterChart,
    ScatterChartForAI,  
    TwoDHistogram
  },
  data() {
    return {
      currentStep: 0,
      steps: [
        { label: "Qualify fluid for composition correction" },
        { label: "Locate Fluid in the Existing Multidimension Space​" },
        { label: "Calculate AI-ML Model Parameters and Similarity Index" },
        { label: "Correct Plus Fraction Properties with AI-ML Models" },
        { label: "Correct STL Compositions with AI-ML Models" },
        { label: "Correct Live Compositions with AI-ML Models" },
      ],
      stepTexts: [
        {
          text: "Qualify/Reject the uncorrected STL composition input for Compo++ AI-ML correction",
          label: "Qualify fluid for composition correction",
        },
        {
          text: "Locate Fluid in the Existing Multidimension Space",
          label: "Locate Fluid in the Existing Multidimension Space",
          image: require("@/assets/images/AiCorrection/step-two.png"),
        },
        {
          text: "Calculate weighted distances, AI-ML models parameters for C36+’s wt%, SG & MW correction including similarity Index of AI-ML model for prediction",
          label: "Calculate AI-ML Model Parameters and Similarity Index",
          image: require("@/assets/images/AiCorrection/step-three.png"),
        },
        {
          text: "Correct C36+ wt%, C36+ density, C36+ MW with Compo++ AI-ML models",
          label: "Correct Plus Fraction Properties with AI-ML Models",
          tableHtml: `<table class="table">
                      <thead>
                        <tr class="header-row">
                          <th>Parameter Correction</th>
                          <th>Uncorrected</th>
                          <th>Corrected</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>STL C36+, wt%</td>
                          <td>${(this.sampleData?.originalComposition?.c36plus_MassFraction || 0).toFixed(3)} ${(this.sampleData?.originalComposition?.c36plus_MassFraction_UOM || '')}</td>
                          <td>${(this.sampleData?.correctedComposition?.c36plus_MassFraction || 0).toFixed(3)} ${(this.sampleData?.correctedComposition?.c36plus_MassFraction_UOM || '')}</td>
                        </tr>
                        <tr>
                          <td>STL C36+ Density, g/cc</td>
                          <td>${(this.sampleData?.originalComposition?.c36plus_Density || 0).toFixed(3)} ${(this.sampleData?.originalComposition?.c36plus_Density_UOM || '')}</td>
                          <td>${(this.sampleData?.correctedComposition?.c36plus_Density || 0).toFixed(3)} ${(this.sampleData?.correctedComposition?.c36plus_Density_UOM || '')}</td>
                        </tr>
                        <tr>
                          <td>STL C36+MW, g/mol</td>
                          <td>${(this.sampleData?.originalComposition?.c36plus_MW || 0).toFixed(2)} ${(this.sampleData?.originalComposition?.c36plus_MW_UOM || '')}</td>
                          <td>${(this.sampleData?.correctedComposition?.c36plus_MW || 0).toFixed(2)} ${(this.sampleData?.correctedComposition?.c36plus_MW_UOM || '')}</td>
                        </tr>
                      </tbody>
                    </table>`,
        },
        {
          text: "Calculate and report AI-ML corrected plus fraction properties for STL",
          label: "Correct STL Compositions with AI-ML Models",
          image: require("@/assets/images/AiCorrection/step-six.png"),
        },
        {
          text: "Calculate and report AI-ML corrected plus fraction properties for live oil",
          label: "Correct Live Compositions with AI-ML Models",
          image: require("@/assets/images/AiCorrection/step-six.png"),
        },
      ],
      selected_file: null,
      files: [],
      chartType:"all",
      filterData: {
        flashed_gas: true,
        sto_corrected_composition: true,
        sto_uncorrected_composition: true,
        separator_liquid_corrected: true,
        separator_liquid_uncorrected: true,
        separator_gas: true,
        reservoir_fluid_corrected: true,
        reservoir_fluid_uncorrected: true,
      },
      aiLocalSampleData:null,
      correctionBarPlot:null,
    };
  },
  props: {
    sampleData: Object,
    yAxisScale: Boolean
  },
  computed: {
    currentStepText() {
      return this.stepTexts[this.currentStep] || "";
    },
    stepInfoClass() {
      return {
        "d-flex": this.currentStep < 3,
        "flex-column": this.currentStep > 3,
      };
    },
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalWorkFlow;
      if (elem) {
        elem.click();
      }
    },
    nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        this.currentStep++;
        this.chartType=(this.currentStep === 4)?'sto':'all';
        this.chartsFilter();
      }
    },
    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        this.chartType=(this.currentStep === 4)?'sto':'all';
        this.chartsFilter();
      }
    },
    setStep(index) {
      this.currentStep = index;
      this.chartType=(this.currentStep === 4)?'sto':'all';
      this.chartsFilter();
    },

    async chartsFilter()
    {
      // STO
      if(this.currentStep === 4)
      {
        this.filterData= {
          flashed_gas: false,
          sto_corrected_composition: true,
          sto_uncorrected_composition: true,
          separator_liquid_corrected: false,
          separator_liquid_uncorrected: false,
          separator_gas: false,
          reservoir_fluid_corrected: false,
          reservoir_fluid_uncorrected: false,
        };
        this.aiLocalSampleData = getFilteredData(this.filterData, this.sampleData, this.sampleData);
      }
      // 
      if(this.currentStep === 5)
      {
        this.filterData= {
          flashed_gas: false,
          sto_corrected_composition: false,
          sto_uncorrected_composition: false,
          separator_liquid_corrected: true,
          separator_liquid_uncorrected: true,
          separator_gas: false,
          reservoir_fluid_corrected: true,
          reservoir_fluid_uncorrected: true,
        };
        this.aiLocalSampleData = getFilteredData(this.filterData, this.sampleData, this.sampleData);
      }

      if(this.currentStep === 0 && this.sampleData?.id != null)
      {
        try {
              const response = await axios.get(`samples/${this.sampleData.id}/correction_bar_plot`);
              if (response.data && response.data.data) {
                this.correctionBarPlot = response.data.data;
                } else {
                  throw new Error("Data not found");
                }
            } catch (error) {
              console.error("Error loading correction bar plot", error);
            } finally {
            }
      }
      
    },
  },
  watch: {
    sampleData: {
      immediate: true,
      handler(newSampleData) {
        this.aiLocalSampleData = JSON.parse(JSON.stringify(newSampleData));
        this.chartsFilter();
      },
    },
  },
};
</script>

<style scoped>
.ai-step-info {
  width: 100%;
}

.ai-step-wrapper .d-flex .ai-step-info {
  width: calc(100% - 625px);
  padding-right: 16px;
}

.ai-step-image {
  width: 100%;
  padding-right: 12px;
}

.ai-step-wrapper .d-flex .ai-step-image {
  width: 625px;
}

/* .js-plotly-plot .plotly .main-svg {
    top: -88px !important;
    left: -70px !important;
} */
</style>