<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#ComparisonData"
      ref="openModalComparisonData"
    ></button>
    <div class="modal fade" tabindex="-1" role="dialog" id="ComparisonData">
      <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-top">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                ref="closeModalComparisonData"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="row no-gutters w-100 saved-filter-option">
              <div class="col-12 col-lg-4 col-xl-6">
                <ChartDataTopLeftComponent
                  @sample-updated="fetchNewSampleData"
                  :location="this.sampleData?.location"
                  :sampleData="this.sampleData"
                />
              </div>
              <div class="col-12 col-lg-8 col-xl-6">
                <ComparisonModalRightComponent
                  :isCheckedPercentage="isCheckedPercentage"
                  :sampleData="this.sampleData"
                  @update:isCheckedPercentage="updateIsCheckedPercentage"
                  @update:isChecked="updateIsChecked"
                />
              </div>
            </div>
          </div>
          <div class="modal-body">
            <ModalTableComponent
              :sampleData="this.sampleData"
              :isCheckedPercentage="isCheckedPercentage"
              :isChecked="isChecked"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartDataTopLeftComponent from '../ChartDataTopLeftComponent.vue';
import ComparisonModalRightComponent from "./ComparisonModalRightcomponent.vue";
import ModalTableComponent from './ModalTableComponent.vue';

export default {
  components: {
    ChartDataTopLeftComponent,
    ComparisonModalRightComponent,
    ModalTableComponent,
  },
  name: "DataComparisonModalComponent",
  props: {
    location: Object,
    sampleData: Object,
  },
  data() {
    return {
      isChecked: false,
      isCheckedPercentage: false,
    };
  },
  methods: {
    openModal() {
      var elem = this.$refs.openModalComparisonData;
      if (elem) {
        elem.click();
      }
    },
    updateIsCheckedPercentage(value) {
      this.isCheckedPercentage = value;
    },
    updateIsChecked(value) {
      this.isChecked = value;
    },
    fetchNewSampleData(newSampleId) {
      var elem = this.$refs.closeModalComparisonData;
      if (elem) {
        elem.click();
      }
      this.$emit("sample-updated", newSampleId);
    },
  },
};
</script>
