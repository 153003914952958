<template>
  <div id="scatterChartAI" class="px-4"></div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  name: "ScatterChartForAI",
  props: {
    localSampleData: Object,
    yAxisScale: Boolean,
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      if (!this.localSampleData) {
        console.error('No sample data available to create the chart.');
        return;
      }

      const correctedComposition = this.localSampleData?.correctedComposition?.composition || [];
      const originalComposition = this.localSampleData?.originalComposition?.composition || [];

      const colorMapCorrected = {
          stockTankGas_MoleFraction: "#4DC671",
          stockTankOil_MoleFraction: "#2A64B4",
          separatorGas_MoleFraction: "#C74AF6",
          separatorLiquid_MoleFraction: "#180AB7",
          reservoirLiquid_MoleFraction: "#38C7C5",
        };
  
        const colorMapUncorrected = {
          stockTankGas_MoleFraction: "#4DC671",
          stockTankOil_MoleFraction: "#76A715",
          separatorGas_MoleFraction: "#C74AF6",
          separatorLiquid_MoleFraction: "#C46CBE",
          reservoirLiquid_MoleFraction: "#BCBBD6",
        };

      const keys = new Set();
      correctedComposition.forEach(item => Object.keys(item).forEach(key => keys.add(key)));
      originalComposition.forEach(item => Object.keys(item).forEach(key => keys.add(key)));

      const keysArray = Array.from(keys).filter(key => key !== 'component' && key !== 'mw' && key !== 'mw_UOM');

      const data = [];
      let xAxisComponents = correctedComposition.length > 0
        ? correctedComposition.map(item => item.component)
        : originalComposition.map(item => item.component);

      const createTrace = (composition, labelPrefix, colorMap, opacity) => {
        keysArray.forEach(key => {
          const yData = xAxisComponents.map(component => {
            const item = composition.find(i => i.component === component);
            return item ? item[key] : null;
          });

          const color = colorMap[key];

          data.push({
            x: xAxisComponents,
            y: yData,
            name: `${labelPrefix} (${key})`,
            text: yData.map(value => value !== null ? `${value}` : ''),
            type: 'scatter',
            textposition: 'top center',
            marker: { color: color, opacity: opacity },
          });
        });
      };

      createTrace(correctedComposition, 'Corrected', colorMapCorrected, 0.7);
      createTrace(originalComposition, 'Uncorrected', colorMapUncorrected, 0.5);

      const layout = {
        title: 'Corrected vs Uncorrected Compositions',
        margin: {
          l: 50,
          r: 50,
          t: 50,
          b: 50,
        },
        xaxis: {
          title: 'Components',
          tickmode: 'linear',
          range: data.length === 0 ? [0, 1] : undefined, 
        },
        yaxis: {
          title: 'Compositions (mole%)',
          type: this.yAxisScale ? 'log' : 'linear',
          range: data.length === 0 ? [0, 1] : undefined, 
        },
        showlegend: false,
        modebar: {
          remove: ['toImage'],
        }
      };

      const config = {
        responsive: true,
        displayModeBar: true,
        displaylogo: false,
      };

      Plotly.newPlot('scatterChartAI', data, layout, config);
    }
  },
  watch: {
    localSampleData: {
      immediate: true,
      handler(newSampleData) {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    },
    yAxisScale: {
      handler() {
        this.$nextTick(() => {
          this.createChart();
        });
      }
    }
  }
};
</script>

<style scoped>
/* Add any specific styles for the chart component here */
</style>
