<template>
  <div class="footer">
    <div class="compo-logo">
        <img :src="compo_logo" alt="compo++">
    </div>
    <div class="fd_logo">
        <img :src="fd_logo" alt="Fluidsdata">
    </div>
  </div>
</template>

<script>
export default {
    name: "FooterComponent",
    data() {
        return {
            compo_logo: require("../../assets/images/footer/compo++.png"),
            fd_logo: require("../../assets/images/login/fd-logo.jpeg")
        }
    }
}
</script>