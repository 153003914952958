<template>
  <div>
    <FilterDataModalComponent ref="filterModal" @apply-filter="applyFilter" />
    <ImportModalComponent ref="importModal"/>

    <div class="table-top-right">
      <div class="form-check">
        <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="exampleCheckbox1" v-model="isCorrected"
          @change="aiCorrectedCLick" />
        <label class="form-check-label" for="exampleCheckbox1">
          Show AI Corrected
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="exampleCheckbox2" v-model="isAnchorPoint"
          @change="anchorPointClick" />
        <label class="form-check-label" for="exampleCheckbox2">
          Show Anchor Points
        </label>
      </div>
      <button type="button" class="btn" @click="showModal">
        <img :src="import_data" alt="filter_data" />
        <span>Filter Data</span>
      </button>
       <button type="button" class="btn"  @click="showImportModal">
        <img :src="import_data" alt="import" />
        <span>Import</span>
      </button>
      <button type="button" class="btn" @click="exportData" :disabled="loading">
        <span v-if="loading">
          <span>Exporting...</span>
        </span>
        <span v-else class="export-sec">
          <img :src="export_data" alt="export" />
          <span>Export</span>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import FilterDataModalComponent from "./Filters/FilterDataModalComponent.vue";
import ImportModalComponent from './ImportModalComponent.vue';

export default {
  name: "TableTopRightComponent",
  components: {
    FilterDataModalComponent,
    ImportModalComponent
  },
  data() {
    return {
      isCorrected: false,
      isAnchorPoint: false,
      export_data: require("../../assets/images/table/export.png"),
      import_data: require("../../assets/images/table/import.png"),
      isModalVisible: false,
      loading: false,
    };
  },
  props: {
    pagination: Object,
    sortColumn: String,
    sortDirection: String,
    filterData: Object,
  },
  methods: {
    showModal() {
      this.$refs.filterModal.openModal();
    },
    showImportModal() {
      this.$router.push("/sample/import/ai-correction");
      // this.$refs.importModal.openModal();
    },
    applyFilter(filterData) {
      this.isCorrected = filterData.isCorrected;
      this.$emit("apply-filter", filterData);
    },
    aiCorrectedCLick() {
      this.$emit("isCorrected", (this.isCorrected==true)?true:'');
    },
    anchorPointClick() {
      this.$emit("isAnchorPoint", (this.isAnchorPoint==true)?true:'');
    },
    async exportData() {
      this.loading = true;
      try {
        const response = await axios.get(`samples/summary?pageSize=${this.pagination.totalItems}`,
          {
            params: {
              asset: (this.filterData.asset.length > 0) ? JSON.stringify(this.filterData.asset) : '',
              field: (this.filterData.field.length > 0) ? JSON.stringify(this.filterData.field) : '',
              well: (this.filterData.well.length > 0) ? JSON.stringify(this.filterData.well) : '',
              reservoir: (this.filterData.reservoir.length > 0) ? JSON.stringify(this.filterData.reservoir) : '',
              sampleType: (this.filterData.sampleType.length > 0) ? JSON.stringify(this.filterData.sampleType) : '',
              // id:(this.filterData.sample.length>0)?JSON.stringify(this.filterData.sample.map(sample => sample.id)):'',
              // name:(this.filterData.sample.length>0)?JSON.stringify(this.filterData.sample.map(sample => sample.name)):'',
              name: (this.filterData.sample.length > 0) ? JSON.stringify(this.filterData.sample) : '',
              isCorrected: this.filterData.isCorrected,
              isAnchorPoint: this.filterData.isAnchorPoint,
              sortColumn: (this.sortColumn) ? JSON.stringify(this.sortColumn) : '',
              sortDirection: (this.sortDirection) ? JSON.stringify(this.sortDirection) : '',
            }
          }
        );

        const csvData = this.convertToCSV(response.data.data);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.setAttribute('download', 'summary_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error exporting data:', error);
      } finally {
        this.loading = false;
      }
    },
    convertToCSV(data) {
      const headerMapping = {
        "ID Number": "sampleID",
        "Asset": "asset",
        "Field": "field",
        "Reservoir": "reservoir",
        "Well": "well",
        "Sample": "sampleName",
        "Anchor Point Data": "isAnchorPoint",
        "AI Corrected": "isCorrected",
      };

      const headers = Object.keys(headerMapping);

      if (!Array.isArray(data)) {
        console.error('Expected an array but got:', data);
        return '';
      }

      const csvRows = [];
      csvRows.push(headers.join(','));
      data.forEach(row => {
        const values = headers.map(header => `"${row[headerMapping[header]] || ''}"`);
        csvRows.push(values.join(','));
      });

      return csvRows.join('\n');
    },
  },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
