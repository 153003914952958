<template>
  <div class="table-wrapper">
    <div class="container-fluid">
      <div v-if="loading" class="loader-overlay">
        <CircularLoader />
      </div>
      <div v-else>
        <div class="row no-gutters align-items-center">
          <div class="col-12 col-xl-6">
            <TableTopLeftComponent :filterData="filterData" />
          </div>
          <div class="col-12 col-xl-6">
            <TableTopRightComponent
              :sortColumn="sortColumn"
              :sortDirection="sortDirection"
              :filterData="filterData" 
              @isCorrected="isCorrected" 
              @isAnchorPoint="isAnchorPoint" 
              @apply-filter="applyFilter" 
              :pagination="pagination" />
          </div>
          <div class="col-12">
            <TableDataComponent 
              :tableData="tableData" 
              :pagination="pagination" 
              @changePage="fetchData"
              @changeItemsPerPage="updateItemsPerPage"
              @sortTable="updateSorting"
              :sortColumn="sortColumn"
              :sortDirection="sortDirection"
            />
          </div>
        </div>
        <div v-if="error" class="error-message">{{ errorMessage }}</div>
        <div v-if="!error && tableData.length === 0" class="no-data-message">No data found</div>
      </div>
    </div>
  </div>
</template>

<script>
import TableDataComponent from './TableDataComponent.vue';
import TableTopLeftComponent from "./TableTopLeftComponent.vue";
import TableTopRightComponent from "./TableTopRightComponent.vue";
import CircularLoader from '../Loader/CircularLoader.vue';
import axios from 'axios';

export default {
  Name: "TableComponent",
  components: { TableTopLeftComponent, TableTopRightComponent, TableDataComponent, CircularLoader },
  data() {
    return {
      filterData: {
        asset: [],
        field: [],
        reservoir:[],
        well: [],
        sample:[],
        sampleType:[],
        isCorrected:'',
        isAnchorPoint:'',
        filterType:'',
      },
      tableData: [],
      pagination: {
        currentPage: 1,
        itemsPerPage: 10,
        totalPages: 0,
        totalItems: 0
      },
      loading: false,
      error: false,
      errorMessage: '',
      sortColumn: '',
      sortDirection: '',
    };
  },
  methods: {
    applyFilter(filterData)
    {
      this.filterData=filterData;
      this.sortColumn=(this.filterData.filterType)?this.sortColumn:'';
      this.sortDirection=(this.filterData.filterType)?this.sortDirection:'';
      this.$emit("apply-filter", this.filterData);
      this.fetchData(1);
    },
    isCorrected(isCorrected)
    {
       this.filterData.isCorrected=isCorrected;
       this.$emit("apply-filter", this.filterData);
       this.fetchData(1);
    },
    isAnchorPoint(isAnchorPoint)
    {
       this.filterData.isAnchorPoint=isAnchorPoint;
       this.$emit("apply-filter", this.filterData);
       this.fetchData(1);
    },
    updateSorting({ column, direction }) {
      this.sortColumn = column;
      this.sortDirection = direction;
      this.fetchData(1);
    },
    async fetchData(page = 1) {
      // this.loading = true;
      this.error = false;
      this.errorMessage = '';
      try {
        const response = await axios.get(`samples/summary`, {
          params: {
            pageNumber: page,
            pageSize: this.pagination.itemsPerPage,
            asset:(this.filterData.asset.length>0)?JSON.stringify(this.filterData.asset):'',
            field:(this.filterData.field.length>0)?JSON.stringify(this.filterData.field):'',
            well:(this.filterData.well.length>0)?JSON.stringify(this.filterData.well):'',
            reservoir:(this.filterData.reservoir.length>0)?JSON.stringify(this.filterData.reservoir):'',
            sampleType:(this.filterData.sampleType.length>0)?JSON.stringify(this.filterData.sampleType):'',
            name:(this.filterData.sample.length>0)?JSON.stringify(this.filterData.sample):'',
            isCorrected:this.filterData.isCorrected,
            isAnchorPoint:this.filterData.isAnchorPoint,
            sortColumn: (this.sortColumn)?JSON.stringify(this.sortColumn):'',
            sortDirection: (this.sortDirection)?JSON.stringify(this.sortDirection):'',
          }
        });
        const data = response.data;
        this.tableData = data.data;
        this.pagination.totalItems = data.pagination.totalRecords;
        this.pagination.totalPages = data.pagination.totalPages;
        this.pagination.currentPage = page;
        if (this.tableData.length === 0) {
          this.error = true;
          this.errorMessage = 'No data found';
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = "Error fetching data";
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
    updateItemsPerPage(itemsPerPage) {
      this.loading = true;
      this.pagination.itemsPerPage = itemsPerPage;
      this.fetchData(1); // Reset to page 1 whenever items per page changes
    }
  },
  created() {
    this.loading = true;
    this.fetchData();
  },
  mounted()
  {

    // this.fetchData(1);
  }
};
</script>
