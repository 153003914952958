<template>
  <div>
    <div class="top-content">
      <h6>Data Clusters</h6>
      <IconComponent />
    </div>
    <ChartDataComponent :filterData="filterData"/>
  </div>
</template>

<script>

import IconComponent from "./IconComponent.vue";
import ChartDataComponent from "./ChartDataComponent.vue";

export default {
  name: 'ChartComponent',
  components: {
    IconComponent,ChartDataComponent
  },
  props: {
    filterData: Object,
  },
}
</script>
