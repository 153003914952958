<template>
  <div class="import-sample mt-4 mt-lg-0">
    <h6>Select samples to import...</h6>
    <div class="card border-0">
      <div class="card-body p-0">
        <div class="sample-list" ref="sampleList" v-if="samples.length > 0">
          <div
            class="sample-item"
            v-for="sample in samples"
            :key="sample.id"
          >
            <input
              type="radio"
              name="sample"
              class="radio"
              :value="sample"
              v-model="selectedSample"
            />
            <div class="content">
              <p>Sample ID #{{ sample?.id }}</p>
              <p class="mb-0">
                Asset <strong>{{ sample?.location.asset }}</strong> | Field
                <strong>{{ sample?.location.field }}</strong> | Well
                <strong>{{ sample?.location.well }}</strong> | Sample
                <strong>#{{ sample?.name }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="error-message" v-if="samples.length === 0">
          No samples found.
        </div>
        <br/>
        <div class="table-top-right justify-content-start mt-2">
          <button class="btn btn-primary" @click="importData" :disabled="!selectedSample || btnLoading">
            <span v-if="btnLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-else>Begin Import</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ImportSample",
  props: {
    requestID: String,
    selectedFile: Object,
  },
  data() {
    return {
      samples: [],
      selectedSample: [],
      btnLoading: false,
    };
  },
  watch: {
    requestID(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadSamples();
      }
    },
    selectedFile(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.uploadSampleFile();
      }
    }
  },
  methods: {
    async uploadSampleFile() {
      this.$emit('start-loading');
      try {
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('asset', 'Hello');

        const response = await axios.post('/samples/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.samples = response.data.data;
      } catch (error) {
        console.error("Error importing sample file:", error);
      } finally {
        this.$emit('stop-loading');
      }
    },
    async loadSamples() {
      this.$emit('start-loading');
      try {
        const response = await axios.post(`/samples/importFromPVTIntelligence?requestID=${this.requestID}`);
        this.samples = response.data.data;
      } catch (error) {
        console.error("Error importing samples:", error);
      } finally {
        this.$emit('stop-loading');
      }
    },
    async importData() {
      this.btnLoading = true;
      try {
        const sampleData = {
              Data: {
                sample: this.selectedSample
              }
            };
        const response = await axios.post(`/samples`,sampleData);
        if(response.data.statusCode === 201)
        {
            this.$emit("show-dynamic-modal",this.selectedSample, response.data.data.id,"success", "", "Import Successful!", "highlighted", [
            { text: "Finish", class: "btn-secondary", action: "finish" },
            { text: "AI Correction", class: "btn-warning", action: "aiCorrection" },
          ]);
        }
        else
        {
            this.$emit("show-dynamic-modal",[],0, "error", "", "Error Loading File: Incorrect File Format.", "normal", [
            { text: "Ok", class: "btn-warning", action: "closeModal" },
          ]);
        }
       
      } catch (error) {
        this.$emit("show-dynamic-modal",0, "error", "", "Error Loading File: Incorrect File Format.", "normal", [
            { text: "Ok", class: "btn-warning", action: "closeModal" },
          ]);
        console.error('Error loading samples:', error);
      } finally {
        this.btnLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.sample-list {
  max-height: 450px;
  overflow-y: auto;
}

.loading-indicator {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: gray;
}
</style>
