<template>
 <div v-if="showAlert" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ alertMessage }}
    </div>
<router-view></router-view>
</template>

<script>
import EventBus from './utils/eventBus';
export default {
  name: 'App',
  data() {
    return {
      showAlert: false,
      alertMessage: ''
    };
  },
   created() {
    EventBus.on('network-error', (message) => {
      this.alertMessage = message;
      this.showAlert = true;
    });
  },
  beforeUnmount() {
    EventBus.off('network-error');
  }
}
</script>
