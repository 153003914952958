<template>
  <div class="view-sample-dropdown">
    <button @click="toggleDropdown">
      <i class="fa-solid fa-chevron-up" v-if="dropdownVisible"></i>
      <i class="fa-solid fa-chevron-down" v-else></i>
    </button>
    <div v-if="dropdownVisible" class="dropdown-menu" ref="dropdownMenu">
      <div v-for="sample in samples" :key="sample.sampleID" @click="selectSample(sample)" class="dropdown-item">
        {{ sample.sampleName }}
      </div>
      <div v-if="isLoading" class="loading-indicator">Loading...</div>
      <div ref="loadMoreTrigger" class="load-more-trigger"></div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "DropdownComponent",
  data() {
    return {
      selectedSample: null,
      dropdownVisible: false,
      samples: [],
      page: 1,
      perPage: 10,
      isLoading: false,
      hasMore: true,
      totalItems: 0,
      observer: null,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
      if (this.dropdownVisible) {
        this.resetSamples();
        this.$nextTick(() => {
          this.observeScroll();
          setTimeout(() => {
        this.addClickOutsideListener();
      }, 0);
        });
      } else {
        this.disconnectObserver();
        this.removeClickOutsideListener();
      }
    },
    async loadSamples() {
      if (this.isLoading || !this.hasMore) return;

      this.isLoading = true;

      try {
        const response = await axios.get(`/samples/summary`, {
          params: {
            pageSize: this.perPage,
            pageNumber: this.page,
          },
        });

        const newSamples = response.data.data;
        this.totalItems = response.data.totalItems;

        this.samples = [...this.samples, ...newSamples];

        if (this.samples.length >= this.totalItems) {
          this.hasMore = false;
        }

        this.page++;
      } catch (error) {
        console.error('Error loading samples:', error);
      } finally {
        this.isLoading = false;
      }
    },
    resetSamples() {
      this.page = 1;
      this.samples = [];
      this.hasMore = true;
      this.loadSamples();
    },
    selectSample(sample) {
      this.selectedSample = sample;
      this.dropdownVisible = false;
      this.$emit('sample-selected', sample);
      if (this.$route.name === 'ViewSummary') {
        this.$router.push(`/summary/view/${sample.sampleID}`);
      } 
    
      this.disconnectObserver();
    },
    observeScroll() {
      const options = {
        root: this.$refs.dropdownMenu,
        rootMargin: '0px',
        threshold: 1.0,
      };

      this.observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && this.hasMore) {
          this.loadSamples();
        }
      }, options);

      if (this.$refs.loadMoreTrigger) {
        this.observer.observe(this.$refs.loadMoreTrigger);
      }

      if (this.$refs.dropdownMenu) {
        this.$refs.dropdownMenu.addEventListener('scroll', this.handleScroll);
      }
    },
    handleScroll() {
      const bottom = this.$refs.dropdownMenu.scrollHeight - this.$refs.dropdownMenu.scrollTop === this.$refs.dropdownMenu.clientHeight;
      if (bottom && this.hasMore && !this.isLoading) {
        this.loadSamples();
      }
    },
    disconnectObserver() {
      if (this.observer && this.$refs.loadMoreTrigger) {
        this.observer.unobserve(this.$refs.loadMoreTrigger);
      }
      if (this.$refs.dropdownMenu) {
        this.$refs.dropdownMenu.removeEventListener('scroll', this.handleScroll);
      }
    },
    addClickOutsideListener() {
      document.addEventListener('click', this.handleClickOutside);
    },
    removeClickOutsideListener() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    handleClickOutside(event) {
  if (
    this.$refs.dropdownMenu &&
    !this.$refs.dropdownMenu.contains(event.target) &&
    !this.$el.contains(event.target)
  ) {
    this.dropdownVisible = false;
    this.removeClickOutsideListener();
    this.disconnectObserver();
  }
}
  },
  beforeDestroy() {
    this.disconnectObserver();
     this.removeClickOutsideListener();
  },
};
</script>

<style scoped>
.view-sample-dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  /* width: 100%; */
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}

.loading-indicator {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: gray;
}

.load-more-trigger {
  height: 1px;
}
</style>
