<template>
  <div class="icons-wrapper">
    <div class="icon-content">
      <div class="icon-img-wrap">
        <img :src="csv_download" alt="csv_download" />
      </div>
    </div>
    <div class="icon-content">
      <div class="icon-img-wrap">
        <img :src="image_download" alt="image_download" />
      </div>
    </div>
    <div class="icon-content">
      <div class="icon-img-wrap">
        <img :src="expand" alt="expand" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconComponent",
  data() {
    return {
      csv_download: require("../../assets/images/icons/csv-download.png"),
      image_download: require("../../assets/images/icons/image-download.png"),
      expand: require("../../assets/images/icons/expand.png"),
    };
  },
};
</script>