<template>
  <div class="main-page">
    <h1>Error</h1>
    <p>There was an issue with your request. Please try again later.</p>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage'
};
</script>
