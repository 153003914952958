<template>
  <div class="login-footer-sec">
    <div class="footer">
      <div class="compo-logo">
        <img :src="compo_logo" alt="adhnoc" />
      </div>
      <div class="fd_logo">
        <img :src="fd_logo" alt="Fluidsdata" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginFooterComponent",
  data() {
    return {
      compo_logo: require("../../assets/images/navbar/logo.png"),
      fd_logo: require("../../assets/images/login/fd-logo.jpeg"),
    };
  },
};
</script>